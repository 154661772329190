.code-ref-container{
    width: 100%;
}
.code-ref-container .code-ref-box{
    padding: 10px 40px;
    font-family: "Poppins-Bold";
    color: #575d6b;
    font-size: 18px;
    background: rgb(245 241 237);
    border-radius: 10px;
    border: 2px solid #575d6b;
    border-style: dashed;
}
.btn-system-primary{
    background: var(--tertiary-main);
    color: var(--primary-main);
    padding: 10px;
    border-radius: 10px;
}
.btn-system-primary:hover{
    color: var(--primary-light);
}
.btn-system-white{
    background: white;
    color: var(--tertiary-main);
    padding: 10px;
    border-radius: 10px;
    border-color: var(--tertiary-main);
}
.btn-system-white:hover{
    color: var(--primary-light);
}
@media (max-width: 1200px) {
    .media-size{
        min-width: 100%;
    }
}

