.menu {
  background-color: rgba(255, 255, 255, 1);
  padding: 30px 15px 20px 15px;
}
.menu-header {
  margin-bottom: 12px;
  display: flow-root;
  width: 248px;
}
.btn-float {
  float: right;
  transform: translate(-6px, -23px);
}
.menu-text {
  float: left;
}
.items-group {
  margin: 10px 0px 10px;
}
.items-group .items-group-title {
  font-weight: 700;
  text-align: start;
  padding-left: 5px;
  padding-bottom: 10px;
}
.items-group .item-container {
  margin-bottom: 10px;
}

.items-group .item-box {
  cursor: pointer;
  margin-bottom: 0;
}
.items-group .item-box:hover {
  background: #dedede;
  border-radius: 20px;
}
.items-group .item-childs {
  cursor: pointer;
  overflow: hidden;
}
.items-group .item-childs .active {
  /*  background: red; */
  background: #dedede;
}
.items-group .item-childs a div {
  background: white;
  margin-left: 3px;
}
.items-group .item-childs.none {
}
/* .items-group .item-container:hover .item-childs.none {
    height: 10% !important;
    background: gray !important;
  } */

a:link {
  text-decoration: none;
}
.btn-cancel,
.btn-logout {
  margin: auto;
  margin-bottom: 4px;
}
.btn-group-logout {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 1fr;
}
@media screen and (max-width: 470px) {
  .btn-group-logout {
    grid-template-columns: repeat(1, 1fr);
  }
}

.arrow {
  border: solid #b9de2c;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  position: absolute;
  right: 31px;
  margin-top: -32px;
}
.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
