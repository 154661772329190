.cardResumen {
    background-color: rgba(247, 247, 247, 1);
    border-radius: 16px;
    padding: 10px 10px 14px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  .contentCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    height: auto;
  }
  .frame551 {
    position: relative;
  }
  .image {
    border-radius: 22px;
    position: relative;
  }
  .img {
    width: 100%;
    min-width: 300px;
    height: 250px;
    border-radius: 8px;
    object-fit: cover;
  }
  .frame550 {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* position: absolute; */
    left: 0;
    top: 11px;
  }
  .frame562 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: auto;
    /* align-self: stretch; */
  }
  .dataCard {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .frame558 {
    margin-bottom: 8px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .nombreYPrecio {
    display: flex;
    align-items: flex-end;
    width: 306px;
  }
  .body1 {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: rgba(49, 36, 120, 1);
    flex: 1;
    text-align: start;
  }
  .body1Bold {
    /* width: 121px; */
    font-family: "Poppins";
    font-size: 13px;
    font-weight: 700;
    line-height: 22px;
    color: rgba(49, 36, 120, 1);
    align-self: stretch;
    display: flex;
    align-items: flex-end;
    text-align: right;
  }
  .ubicacionYLabel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
  }
  .caption {
    /* width: 175px; */
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: rgba(87, 93, 107, 1);
  }
  .captionTwo {
    /* width: 121px; */
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: rgba(87, 93, 107, 1);
    text-align: right;
  }
  .separador {
    width: 305.5px;
    margin-bottom: 8px;
  }
  .labels {
    padding: 0 0 0 185px;
    height: 18px;
    position: relative;
  }
  .recaudo {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    left: 0;
    top: -2px;
  }
  .captionThree {
    width: 98px;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: rgba(87, 93, 107, 1);
    align-self: stretch;
    display: flex;
    align-items: center;
  }
  .body2Bold {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    color: rgba(49, 36, 120, 1);
    text-align: right;
    margin-bottom: 0px;
    margin-top: 10px;
  }
  label.body2Bold {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    color: rgba(49, 36, 120, 1);
    text-align: right;
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .tir {
    padding: 0 12px 0 76px;
    position: relative;
  }
  .captionFour {
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: rgba(87, 93, 107, 1);
    text-align: right;
  }
  .iconhelpTwo {
    padding: 1px 3px 23px 1px;
    display: flex;
    align-items: flex-start;
    position: absolute;
    left: 113px;
    bottom: -14px;
  }
  .iconhelp {
    width: 8px;
    height: 8px;
  }
  .indicadores {
    padding: 0 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    margin-top: 20px;
  }
  .statusBar {
    width: 50%;
  }
  .indicadorRendimiento {
    background-color: rgba(220, 239, 149, 1);
    border-radius: 5px;
    padding: 0 5px;
    display: flex;
    align-items: flex-start;
  }