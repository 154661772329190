
@media (min-width: 768px) {
    .custom-col-1 .col-md-6.custom {
      -ms-flex: 0 0 60% !important;
      flex: 0 0 60% !important;
      max-width: 60% !important;
    }
    .custom-col-1 .col-md-4.custom {
      -ms-flex: 0 0 40% !important;
      flex: 0 0 40% !important;
      max-width: 40% !important;
    }
  }
  .row.custom {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 0px;
    margin-left: 0px;
  }
  .col-custom-md-7 {
    -ms-flex: 0 0 70%;
    flex: 0 0 70%;
    max-width: 70%;
  }
  
  .col-custom-md-3 {
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%;
  }
  .col-custom-md-6 {
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }
  .col-custom-md-4 {
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }
  @media (max-width: 1300px) {
    .custom-col-1 .col-md-8 {
      -ms-flex: 0 0 60% !important;
      flex: 0 0 60% !important;
      max-width: 60% !important;
    }
    .custom-col-1 .col-md-4 {
      -ms-flex: 0 0 40% !important;
      flex: 0 0 40% !important;
      max-width: 40% !important;
    }
  }
  @media (max-width: 1040px) {
    .custom-col-1 .col-md-8 {
      -ms-flex: 0 0 50% !important;
      flex: 0 0 50% !important;
      max-width: 60% !important;
    }
    .custom-col-1 .col-md-4 {
      -ms-flex: 0 0 50% !important;
      flex: 0 0 50% !important;
      max-width: 50% !important;
    }
    .custom-col-1 .col-md-6 {
      -ms-flex: 0 0 100% !important;
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }
  @media (max-width: 700px) {
    .custom-col-1 .col-md-8 {
      -ms-flex: 0 0 100% !important;
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
    .custom-col-1 .col-md-4 {
      -ms-flex: 0 0 100% !important;
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
    .custom-col-1 .col-md-6 {
      -ms-flex: 0 0 100% !important;
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }
  .box-info-data{
    margin: auto 0;
  }
  .box-info-data.overflow-hidden{
    overflow: hidden;
  }
  