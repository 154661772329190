.cardBalance {
    background-color: var(--common-white);
    border-radius: 16px;
    padding: 15px;
    box-shadow: 0 12px 24px -4px rgba(145, 158, 171, 0.12),
      0 0 2px 0 rgba(145, 158, 171, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }
  .information {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .titleCard {
    font-family: "Poppins-Bold";
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: rgba(33, 43, 54, 1);
    margin-bottom: 8px;
  }
  .saldocop {
    font-family: "Poppins-Bold";
    font-size: 22px;
    font-weight: 700;
    line-height: 36px;
    color: var(--secondarycolorpalette-lightblue-main);
  }
  .saldocop-small{
    font-family: "Poppins-Bold";
    font-size: 14px;
    font-weight: 700;
    line-height: 8px;
    color: var(--secondarycolorpalette-lightblue-main);
    text-align: start;
  }
  .saldocop-medium{
    font-family: "Poppins-Bold";
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    color: var(--secondarycolorpalette-lightblue-main);
    text-align: start;
  }
  .saldocopEmphasis1 {
    font-family: "Poppins";
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    color: rgba(33, 43, 54, 1);
  }
  .saldocopEmphasis2 {
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: var(--secondarycolorpalette-lightblue-main);
  }
  .saldoDeviseCoin {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: rgba(87, 93, 107, 1);
  }
  .saldoDeviseCoinEmphasis1 {
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: rgba(87, 93, 107, 1);
  }
  .iconBalanceTwo {
    background-color: var(--tertiary-main);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 66px;
    height: 60px;
  }
  .iconBalance {
    height: 46px;
    width: 46px;
  }
  .cardBalance .title2{
    color: gray;
    font-size: 12px;
    font-family: 'POPPINS-BOLD';
  }