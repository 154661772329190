.itemMenu {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 11px;
    padding: 8px 8.65px 8px 10px;
    box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .imputContent {
    width: 100%;
    position: relative;
    cursor: pointer;
  }
  .labelContent {
    padding: 11px 22px 11px 10px;
    display: flex;
    align-items: center;
  }
  .label {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    color: rgba(91, 101, 118, 1);
    margin: 0px;
  }
  .iconContent {
    padding: 10px;
    position: absolute;
    right: -2.48px;
    top: 0;
    top: 50%;
    transform: translate(0px ,-50%);
  }
  .iconRight {
    position: absolute;
    left: 10px;
    top: 0;
  }