

  .button-secondary {
    background-color:var(--tertiary-main);
    border: none;
    border-radius: 8px;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    color:var(--primary-main);
  }
  .button {
    background-color:var( --primary-main);
    border: none;
    border-radius: 8px;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    color:var(--tertiary-main);
  }


.label {
  font: var(--buttons-buttonsm);
  text-decoration: none;
}

.buttonMd{
  background-color:var(--tertiary-main);
  border: none;
  border-radius: 8px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  color:var(--primary-main);
  height: auto;
  width: 500px;
}

