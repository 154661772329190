.container-img {  
    overflow: hidden;
    text-align: center;
    display: flex;
}
.container-img img {
    overflow: hidden;
    background: #ccc;
    text-align: center;
    margin: auto;
    max-width: 345px;
    max-height: 490px;
}
.completar-perfil-container{
    margin: auto;
}
.container-box{
    max-width: 485px;
}
.container-main{
    min-height: 500px;
    display: flex;
}
.container-main-body{
    margin: auto;
}
.page-incomplete-profile .btn{
    width: 100%;
}
.wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    margin: auto;
  }
@media (max-width: 1160px) {
    .container-img{
      display: none;
    }
    .wrapper {
        display: grid;
        grid-template-columns: none;
        grid-gap: 10px;
        margin: auto;
      }
  }

  