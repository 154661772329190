@media screen and (max-width: 1730px) {
  .col-custom {
    -ms-flex: 0 0 33.333333333% !important;
    flex: 0 0 33.333333333% !important;
    max-width: 33.333333333% !important;
  }
}
@media screen and (max-width: 1370px) {
  .col-custom {
    -ms-flex: 0 0 50% !important;
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
}
@media screen and (max-width: 1060px) {
  .col-custom {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

