.form-floating-label {
    position: absolute;
    left: 30px;
    top: -10px;
    background: white;
    font-size: 13px;
    padding: 0px 5px;
    color: #b9b9b9;
    height: auto;
    position: absolute;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100% - 50px);
}