.first-col {
  background: #ebebeb;
  border-radius: 8px 0px 0px 8px;
  color: gray;
  font-family: Poppins-Bold;
  font-size: 15px;
}
.middle-col {
  background: #ebebeb;
  color: gray;
  font-family: Poppins-Bold;
  font-size: 15px;
}
.last-col {
  background: #ebebeb;
  border-radius: 0px 8px 8px 0px;
  color: gray;
  font-family: Poppins-Bold;
  font-size: 15px;
}
.col-rendimientos {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: rgba(95, 118, 35, 1);
}
.col-fecha-inversion {
  color: gray;
}
.col-invertido {
  color: gray;
}
.col-propiedad {
  color: gray;
}
.col-completado {
  color: gray;
  background: #b9de2c;
  border-radius: 15px;
  padding: 3px 12px;
  font-size: 13px;
}
.col-pendiente {
  color: gray;
  background: var(--secondarycolorpalette-lightorange-light);
  border-radius: 15px;
  padding: 3px 12px;
  font-size: 13px;
}
.table-investments {
  font-size: 14px;
}
.table-investments td {
  vertical-align: middle;
}
.table-investments .center {
  text-align: center;
}
.table-investments .start {
  text-align: start;
}
.row-component-investments {
  min-height: 120px;
}
td .td-img {
  display: inline-block;
  padding: 4px;
  background: #cce7ef;
  border-radius: 4px;
  margin-right: 2px;
}
td .td-txt {
  display: inline-block;
}
.table-title {
  font-family: Poppins-bold;
  margin: 20px 10px 20px 20px;
  text-align: start;
  font-family: "Poppins-Bold";
  font-size: 16px;
  line-height: 24px;
  color: rgba(33, 43, 54, 1);
  margin-bottom: 8px;
}
.table-title.blue{
  color: #112e6d
}
.col-rendimientos {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: rgba(95, 118, 35, 1);
  text-align: right;
}