.box {
  background-color: rgba(185, 222, 44, 1);
  border-radius: 4px;
  padding: 0px 5px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0px;
}
.tipo {
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 700;
  line-height: 22px;
  color: rgba(49, 36, 120, 1);
  text-align: center;
  margin: 0px 10px;
}