
body {
  margin: 0;
  font-family: Poppins;
}
@font-face {
  font-family: "Poppins";   /*Can be any text*/
  font-style: normal;
  font-weight: normal;
  src: local("Poppins-Regular"),
    url("./fonts/Poppins_1/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Bold";   /*Can be any text*/
  src: local("bold"),
  url("./fonts/Poppins_1/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "Poppins-ExtraLight";   /*Can be any text*/
  src: local("light"),
  url("./fonts/Poppins_1/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: bold;
}


/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
:root {

 
  --common-white: #ffffff; 
  --common-dark: #000000; 
  --primary-lighter: #dcef95; 
  --primary-light: #cbe661; 
  --primary-main: #b9de2c; 
  --primary-dark: #8caa27; 
  --primary-darker: #5f7623; 
  --secundary-lighter: #bff3ff; 
  --secundary-light: #81e7ff; 
  --secundary-main: #00cfff; 
  --secundary-dark: #019fc5; 
  --secundary-darker: #026e8c; 
  --tertiary-ligther: #9891bb; 
  --tertiary-light: #655b9a; 
  --tertiary-main: #312478; 
  --tertiary-dark: #261e60; 
  --tertiary-darker: #1b1949; 
  --text-ondark-primary: #ffffff; 
  --text-onlight-primary: #081730; 
  --text-onlight-secondary: #5b6576; 
  --background-ondark-default: #081730; 
  --background-onlight-default: #f7f7f7; 
  --secondarycolorpalette-lightpurple-lighther: #d2c7f7; 
  --secondarycolorpalette-lightpurple-light: #bbacf2; 
  --secondarycolorpalette-lightpurple-main: #a490ee; 
  --secondarycolorpalette-lightpurple-dark: #7c6fb9; 
  --secondarycolorpalette-lightpurple-darker: #554f83; 
  --secondarycolorpalette-lightblue-ligtheri: #8da7e0; 
  --secondarycolorpalette-lightblue-light: #557bd1; 
  --secondarycolorpalette-lightblue-main: #1c4fc1; 
  --secondarycolorpalette-lightblue-dark: #163e97; 
  --secondarycolorpalette-lightblue-darker: #112e6d; 
  --secondarycolorpalette-hueso-lighter: #f5f1eb; 
  --secondarycolorpalette-hueso-light: #f0eae1; 
  --secondarycolorpalette-hueso-main: #ebe3d7; 
  --secondarycolorpalette-hueso-dark: #b1ada7; 
  --secondarycolorpalette-hueso-darker: #787878; 
  --secondarycolorpalette-lightorange-lighter: #ffdca1; 
  --secondarycolorpalette-lightorange-light: #ffcb72; 
  --secondarycolorpalette-lightorange-main: #ffba43; 
  --secondarycolorpalette-lightorange-dark: #c18f38; 
  --secondarycolorpalette-lightorange-darker: #82642e; 
  --secondarycolorpalette-lightred-ligther: #fed7cf; 
  --secondarycolorpalette-lightred-light: #fec2b7; 
  --secondarycolorpalette-lightred-main: #fdae9f; 
  --secondarycolorpalette-lightred-dark: #bf867d; 
  --secondarycolorpalette-lightred-darker: #815e5c; 
  --secondarycolorpalette-darkblue-ligther: #8f939c; 
  --secondarycolorpalette-darkblue-light: #575d6b; 
  --secondarycolorpalette-darkblue-main: #1f2739; 
  --secondarycolorpalette-darkblue-dark: #182031; 
  --secondarycolorpalette-darkblue-darker: #121a29; 
  --h1: 700 2.5rem/3.125rem Poppins; 
  --h2: 700 2rem/2.625rem Poppins; 
  --h3: 700 1.5rem/2.25rem Poppins; 
  --h4: 700 1.25rem/1.875rem Poppins; 
  --h5: 700 1.125rem/1.6875rem Poppins; 
  --h6: 700 1.0625rem/1.625rem Poppins; 
  --overlineunderline: 700 0.75rem/1.125rem Futura; 
  --subtitle1: 700 1rem/1.5rem Poppins; 
  --subtitle2: 700 0.875rem/1.375rem Poppins; 
  --text-body1: 400 1rem/1.5rem Poppins; 
  --text-body1bold: 700 1rem/1.5rem Poppins; 
  --text-body2: 400 0.875rem/1.375rem Poppins; 
  --text-body2bold: 700 0.875rem/1.375rem Poppins; 
  --text-caption: 400 0.75rem/1.125rem Poppins; 
  --text-captionbold: 700 0.75rem/1.125rem Poppins; 
  --overline: 700 0.75rem/1.125rem Futura; 
  --buttons-buttonsm: 700 0.8125rem/1.375rem Poppins; 
  --buttons-buttonlg: 700 0.9375rem/1.625rem Poppins; 
  --buttons-buttonmd: 700 0.875rem/1.5rem Poppins; 
  --transparent-dark-gray: box-shadow: 0px 8px 16px 0px rgba(145, 158, 171, 0.1);


}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.box-button-types {
  margin-top: 40px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.wrapper-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin: auto;
}
.wrapper-bid-input {
  display: grid;
  grid-template-columns: calc(100% - 64px) 54px;
  grid-gap: 10px;
  margin: auto;
}
.w-col-3{
  grid-template-columns: repeat(3, 1fr);
}
.custom-1 {
  display: grid;
  grid-template-columns: calc(40% - 5px) calc(60% - 5px);;
  grid-gap: 10px;
  margin: auto;
}
.w-right {
  padding: 12px 8px;
  background: var(--secondarycolorpalette-hueso-lighter);
  display: flex;
  flex-flow: column;
  border-radius: 11px;
  box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.05);
}
.w-left {
  padding: 12px 8px;
  /* background: var(--secondarycolorpalette-hueso-lighter); */
  display: flex;
  flex-flow: column;
  border-radius: 11px;
}
.wm-auto{
  margin: auto;
}
.btn-values {
  margin: 15px auto;
}
.btn-values-box{
  display: inline-block;
  background: #dedede;
  margin: 3px 5px;
  padding: 1px 10px;
  border-radius: 3px;
  cursor: pointer;
}
.icon-box-gray {
  background: #eaeaea;
  border-radius: 5px;
  display: flex;
}
.text-extra-light{
  font-family: Poppins-ExtraLight;
  font-size: 14px;
}
.text-subtitle{
  font-family: "Poppins-Bold";
  font-size: 16px;
  color: rgba(28, 79, 193, 1);
  text-align: start;
}
.text-subtitle-dark{
  font-family: "Poppins-Bold";
  font-size: 16px;
  color: var(--tertiary-main);
  text-align: start;
}
.text-small-dark{
  font-family: "Poppins-ExtraLight";
  font-size: 12px;
  color: var(--tertiary-main);
}
a.link{
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}
.page-text-body{
  color: gray;
  font-size: 14px;
  text-align: justify;
}
.page-text-body.padding{
  padding: 10px;
}
.page-text-body.justify{
  text-align: justify;
}
.p-normal-line{
  margin: 0;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* :root { 
  width: 300px;
  height: 100px;
  overflow-y: scroll;
  scrollbar-color: rebeccapurple green;
  scrollbar-width: thin;
} */
/* table-responsive */
*::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 100px;
}
*::-webkit-scrollbar-thumb {
  background-color: var(--secondarycolorpalette-lightblue-ligtheri);
  border-radius: 100px;
  
}
html::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}

html::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 100px;
}

html::-webkit-scrollbar-thumb {
  background-color: var(--secondarycolorpalette-lightblue-ligtheri);
  border-radius: 100px;
}
html::-webkit-scrollbar-thumb:hover {
  background-color: var(--secondarycolorpalette-lightblue-ligtheri);
  border-radius: 100px;
}
html::-webkit-scrollbar-thumb {
  background-image: var(--secondarycolorpalette-lightblue-ligtheri);
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
  border-radius: 100px;
}
html {
  overflow-y: scroll;
  scrollbar-color: var(--secondarycolorpalette-lightblue-ligtheri) white;
  scrollbar-width: thin;
}
.menu-container.show::-webkit-scrollbar-thumb {
  background-color: var(--secondarycolorpalette-lightblue-ligtheri);
  border-radius: 100px;
}
.menu-container.show::-webkit-scrollbar {
  width: 6px;
}

.menu-container.show::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 100px;
}

.menu-container.show::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 100px;
}
.menu-container.show::-webkit-scrollbar-thumb:hover {
  background-color: var(--secondarycolorpalette-lightblue-ligtheri);
  border-radius: 100px;
}
.menu-container.show::-webkit-scrollbar-thumb {
  background-image: var(--secondarycolorpalette-lightblue-ligtheri);
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
  border-radius: 100px;
}
.menu-container.show {
  scrollbar-color: var(--secondarycolorpalette-lightblue-ligtheri) white;
  scrollbar-width: none;
}
.menu-container.show:hover{
  overflow-y: auto;
  overflow-x: auto;
  scrollbar-width: thin;
}
._card-user {
  background-color: var(--secondarycolorpalette-lightblue-darker);
  border-radius: 10px;
  padding: 9px 20px 9px 20px;
  align-items: center;
  width: 250px;
  text-align: start;
  display: flex;
  min-height: 80px;
}
._card-user ._card-header {
  /* width: 100%;
  color: var(--primary-main);
  display: flex;
  font-size: 14px; */
  
  font-size: 14px;
  width:175px;
  color: var(--primary-main);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}
._card-user ._card-body {
  color: var(--primary-main);
  width: 100%;
  display: flex;
  font-size: 12px;
}
.card-custom{
  background-color: rgba(255, 255, 255, 1);
  border-radius: 11px;
  padding: 8px 8.65px 8px 10px;
  box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px 15px;
}
.page-card-subtitle {
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 700;
  line-height: 30px;
  color: rgba(28, 79, 193, 1);
  display: flex;
  padding-bottom: 10px;
}
.icon-box {
  background: #112e6d;
  border-radius: 5px;
  padding: 5px;
  margin: 0px 5px 0px 5px;
  display: flex;
  height: 39px;
  width: 39px;
}
.icon-box svg{
  margin: auto;
}
.user-image{

}
.user-image .user-image-box{
  width: 40px;
  height: 40px;
}
.user-image .user-image-box img{
  background: #f0f0f0;
}
.user-image .user-image-box img{
  width: 40px;
  height: 40px;
  border-radius: 5px;
  object-fit: cover;
}
._card-user ._card-column{
  display: inline-block;
  width: 100%;
  margin-left: 10px;
}

._card-user .user-image {
  display: inline-block;
  
}
.grid-scroll-h-content{
  display: grid;
}
.grid-scroll-h-content .responsive-table{
  overflow: auto;
}
.border-radius{
  background-color: var(--common-white);
    border-radius: 16px;
    box-shadow: 0 12px 24px -4px rgb(145 158 171 / 12%), 0 0 2px 0 rgb(145 158 171 / 20%);
    align-items: center;
    width: 100%;
    height: 100%;
}
.pagination-right{
  width: 100%;
  text-align: right;
  height: 33px;
}
.pagination-right .box-right{
  float: right;
}
.card-container{
  background-color: var(--common-white);
  border-radius: 16px;
  padding: 15px;
  box-shadow: 0 12px 24px -4px rgb(145 158 171 / 12%), 0 0 2px 0 rgb(145 158 171 / 20%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
._card-footer{
  display: block ruby;
  float: right;
}
.MuiDialog-root.MuiModal-root{
  z-index: 99999999999;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded{
  border-radius: 20px;
}
._card-footer-right{
  display: flex;
  margin-top: 10px;
  font-size: 14px;
}
.card-container.content-box{
  /* width: min-content; */
  width: 100%;
  max-width: 500px;
}
._card-footer-right li {
  margin: 0px 10px;
}
li.arrow-right{
  list-style: none;
  list-style-type: none;
}
li.arrow-right::before {
  position: relative;
  /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
  content: "";
  display: inline-block;
  /* By using an em scale, the arrows will size with the font */
  width: 0.6em;
  height: 0.6em;
  border-right: 0.13em solid black;
  border-top: 0.13em solid black;
  transform: rotate(45deg);
  margin-right: 0.5em;
  
}
li.arrow-right.primary::before {
  border-right: 0.13em solid var(--primary-main);
  border-top: 0.13em solid var(--primary-main);
}
li.arrow-right.secondary::before {
  border-right: 0.13em solid var(--secondarycolorpalette-lightblue-main);
  border-top: 0.13em solid var(--secondarycolorpalette-lightblue-main);
}
li.arrow-right.gray::before {
  border-right: 0.13em solid gray;
  border-top: 0.13em solid gray;
}
.breadcrumbs-box {
  margin-bottom: 15px;
  margin-left: 10px;
}
.breadcrumbs-box-title {
  text-align: left;
  color: #112e6d;
  font-size: 18px;
  margin-bottom: 6px;
}
.breadcrumbs-box nav a{
  color: #112e6d;
}
.MuiBreadcrumbs-ol.css-4pdmu4-MuiBreadcrumbs-ol {
  margin: 0;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: .8rem;
  line-height: .8;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.6);
}
.MuiBreadcrumbs-li .MuiTypography-root.MuiTypography-body1 {
  font-size: .8rem;
}
.carousel-box{
  margin-left: 35px;
  margin-right: 35px;
  margin-bottom: 20px;
}
.carousel-container {
  height: 200px;
  width: 100%;
  display: grid;
}
.carousel-item-img {
  padding: 5px;
}
img.image-responsive {
  height: 201px;
  width: 100%;
  object-fit: cover;
  filter: brightness(65%);
}
.container-box{
  margin: auto;
}
.container-main{

}
.title-form{
  font-family: 'Poppins-Bold';
  font-size: 20px;
  color: var(--tertiary-main);
  text-align: start;
}
.sub-title-form {
  font-family: 'Poppins-ExtraLight';
  margin: 20px 0px;
  text-align: justify;
}

@media (max-width: 1600px) {
  .col-custom-2{
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (max-width: 1300px) {
  .col-custom-2{
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.form-error{
  font-size: 12px;
  color: #bb0a00;
}
/*******Cuadrícula**************/
p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
img{
  -moz-user-select: none;
  -webkit-user-select: none;
  pointer-events: none;
  user-select: none;
}
/* .main-head {
  grid-area: header;
}
.content {
  grid-area: content;
}
.main-nav {
  grid-area: nav;
}
.side {
  grid-area: sidebar;
}
.ad {
  grid-area: ad;
}
.main-footer {
  grid-area: footer;
}
.wrapper {
  display: grid;
  grid-gap: 20px;
  grid-template-areas:
    "header"
    "nav"
    "content"
    "sidebar"
    "ad"
    "footer";
}
@media (min-width: 500px) {
  .wrapper {
    grid-template-columns: 1fr 3fr;
    grid-template-areas:
      "header  header"
      "nav     nav"
      "sidebar content"
      "ad      footer";
  }
  nav ul {
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 700px) {
  .wrapper {
    grid-template-columns: 1fr 4fr 1fr;
    grid-template-areas:
      "header header  header"
      "nav    content sidebar"
      "nav    content ad"
      "footer footer  footer"
   }
   nav ul {
     flex-direction: column;
   }
}
.wrapper {
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-gap: 20px;
} */
/* @media screen and (min-width: 40em) {
  .container {
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 2px;
  }
}
@media screen and (min-width: 40em) {
  .container li:nth-child(1) {
    grid-column: 1 / -1;
    grid-row: span 3;
  }
} */
/*********************/


._btn-primary{
  z-index: 1000;
  background: rgb(17, 46, 109);
  color: rgb(185, 222, 44);
}
.btn._btn-primary:hover {
  color: #4ba255;
}
._btn-secondary{
  z-index: 1000;
  background: rgb(185, 222, 44);
  color: rgb(17, 46, 109);
}
.btn._btn-secondary:hover {
  color: rgb(35, 89, 206);
}
._btn-tertiary{
  z-index: 1000;
  background: var(--tertiary-main);
  color: white;
}
.btn._btn-tertiary:hover {
  color: var(--secondarycolorpalette-hueso-main);
}


._btn-gray{
  z-index: 1000;
  background: gray;
  color: rgb(185, 222, 44);
}
.btn._btn-gray:hover {
  color: rgb(152, 180, 39);
}

._btn-danger{
  z-index: 1000;
  background: rgb(178, 87, 87);
  color: rgb(185, 222, 44);
}
.btn._btn-danger:hover {
  color: rgb(152, 180, 39);
}



.no-padding{
  padding: 0px;
}
.form-custom-box{
  width: 100%;
}
.icon-action{
  cursor: pointer;
  margin: auto;
}
.custom-input-box svg{
  margin: auto;
}
.custom-input-box .custom-control.custom-switch{
  margin: auto 0px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.custom-input-box .custom-control.custom-switch .custom-control-label{
  cursor:pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.font-12{
  font-size: 12px;
}
.font-13{
  font-size: 13px;
}
.font-14{
  font-size: 14px;
}
.font-15{
  font-size: 15px;
}
.font-16{
  font-size: 16px;
}
.font-17{
  font-size: 17px;
}
.font-18{
  font-size: 18px;
}
.font-20{
  font-size: 20px;
}
.font-22{
  font-size: 22px;
}
.font-24{
  font-size: 24px;
}
.custom-input-box{
  display: flex;
  width: 100%;
  height: calc(1.9em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .75rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  
}
.custom-input-box-tarea{
  display: flex;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  min-height: calc(1.9em + .75rem + 2px);
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .75rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.custom-input-box-tarea textarea{
  width: 100%;
  border: none;
    outline: none;
    min-height: calc(1.9em + .75rem + 2px);
}
.custom-input-box:focus-within {
  border: thin solid var(--tertiary-ligther);
}
.custom-input-box.size-big {
  font-size: 30px;
  font-family: Poppins-Bold;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid rgb(209, 209, 209);
  height: auto;
  padding: 0;
}
.custom-input-box.size-big:focus-within {
  border-bottom: thin solid var(--tertiary-ligther);
}
.custom-input-box.size-big input{
  width: 100%;
  height: 32px;
  border:0;outline:0;
  color: #495057;
}

.row-component{
  margin-right: 0;
    margin-left: 0;
}
.custom-input-box input{
  width: 100%;
  height: 100%;
  border:0;outline:0;
  color: #495057;
}
.custom-input-box input::placeholder{
  color: #c0c0c0;
}
.custom-input-box select{
  width: 100%;
  height: 100%;
  border:0;outline:0;
  color: #495057;
  background-color: transparent;
}
.nav.nav-tabs{
  background: #eeeeee;
  border-radius: 5px;
  padding: 2px 10px;
  width: 100%;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #112e6d;
  background-color: transparent;
  border-color: transparent transparent #112e6d;
  border-bottom: 2px solid;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
  background-color: transparent;
  color:gray;
}
.tab-content{
  width: 100%;
}
.nav-tabs {
  border-bottom: none;
}

.nav-tabs .nav-link:hover{
  color:gray;
  border-color: transparent transparent rgba(185, 222, 44, 1);
}
.tab-container{
  padding: 5px;
}
.profile-img {
  width: 150px;
  height: 150px;
  display: flex;
  margin: auto;
  border-top-right-radius: 50%;
  overflow: hidden;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  background: #dedede;
}
.picture-profile{
  height: 100%;
  border-top-right-radius: 50%;
overflow: hidden;
border-bottom-right-radius: 50%;
border-bottom-left-radius: 50%;
border-top-left-radius: 50%;
}
.picture-profile .profile-img{
  cursor: pointer;
  
}
.picture-profile .profile-img img{
  object-fit: cover;
  width: 100%;
}
.cursor-hand{
  cursor: pointer;
}
.picture-profile .floating-text {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  top: 50%;
}
.floating-text .svg-transparent-container, .text-transparent-container{
  opacity: 0.6;
}
._form-group{
  width: 100%;
  display: flex;
}

.container-animated {
  animation: fade-in 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@keyframes fade-in {
 0% { opacity: 0; }
 100% { opacity: 1; }
}
/* .container-animated {
  animation: fade-in 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@keyframes fade-in {
  from{
      overflow: hidden;
    margin-left: -100vw;
  }
to{
      overflow: hidden;
    margin-left:0vw;
  }
}  */
.foo-bar{
  font-family: Poppins;
}
.table-container-responsive {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 16px;
  padding: 17px 10px;
  box-shadow: 0 12px 24px -4px rgba(145, 158, 171, 0.12),
    0 0 2px 0 rgba(145, 158, 171, 0.2);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  display: "table";
  table-layout: "fixed";
  width: "100%";
}

.form-btn-group{
  display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: 1.5rem!important;
}
.form-btn-group-left{
  display: flex;
}
.form-btn-box{
  width: inherit;
}
.form-btn-group-right .form-btn-box{
  float: right;
  width: auto;
}
@media screen and (max-width: 575px) {
  .form-btn-group-right .form-btn-box{
    margin-top: 25px;
    width: 100%;
  }
  
}
.hide-arrow a::after{
  display: none;
}
.btn-white::after {
  background-color: #fff;
}


.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 50%;
  margin-top: calc((100vh / 2) - 88px);
  transform: translate(-50%, -50%);
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
.img-float{
  width: 100%;
  height: 100%;
  transition: width .3s, height .3s;
  border: 1px solid;
  border-top-color: currentcolor;
  border-top-style: solid;
  border-top-width: 1px;
  border-radius: 0px 0px 10px 10px;
  color: #dedede;
  border-top: navajowhite;
  padding-top: 10px;
  padding-bottom: 10px;
}
.img-float img.img-box-float{
  width: 200px;
  height: 130px;
  object-fit: cover;
  border-radius: 5px;
  transition: width .3s, height .3s;
}
/* .img-float:hover{
  position: absolute;
  left:50%;
  top:50%;
  transform: translate(-50%,-50%);
  width: 400px;
  height: 400px;
  z-index: 999;
  border-radius: 10px;
  
}
.img-float:hover img.img-box-float{
  width: 100%;
  height: 100%;
  
} */
.wrapper-loaader{
  /* position: absolute;
  width: 100%;
  height: calc(100vh + 45px);
  margin: -15px;
  z-index: 999;
  background: rgba(0,0,0,0.1); */
  position: fixed;
    width: -webkit-fill-available;
    width: -moz-available;
    min-height: calc(100vh + 43px);
    /* height: calc(100% + 50px); */
    top: -44px;
    margin: 0px 0px 0px -13px;
    z-index: 9999999;
    background: rgba(0,0,0,0.1);
}
.wrapper-loaader .spinner-container{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}
a:hover{
  color: var(--secondarycolorpalette-lightblue-darker); /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}
.carousel .slider-wrapper {
  overflow: hidden;
 
  border-radius: 10px !important;
}

.carousel .slide img {
  width: 100%;
  vertical-align: top;
  border: 0;
  height: 500px;
  object-fit: cover;
}
li.thumb img{
  height: 60px;
  object-fit: cover;
  width: 100%;
}
.carousel .thumb.selected, .carousel .thumb:hover {
  border: 3px solid var(--secondarycolorpalette-lightblue-darker) !important;
  border-radius: 5px;
}
.page--subtitle{
  color: var(--secondarycolorpalette-lightblue-darker);
  font-size: 15px;
  line-height: 30px;
  padding-bottom: 10px;
}

.custom-input-box-tarea .text-read-only{
  margin: auto 10px;
  text-align: justify;
  word-break: break-word;
}
.mt-10{
  margin-top: 10px;
}
.mt-20{
  margin-top: 20px;
}
.mt-30{
  margin-top: 30px;
}
.sidebar{
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.custom-progress {
  background-color: var(--secondarycolorpalette-lightblue-darker) !important;
}
.custom-progress-negative{
  background-color: var(--primary-main) !important;
}

.control-arrow.control-next{
  border-radius: 0px 10px 10px 0px;
}
.control-arrow.control-prev{
  border-radius: 10px 0px 0px 10px;
}
.carousel-box-key{
  height: 100%;
}
.carousel-box-key img{  
  max-width: 500px;
  padding: 20px;
  transform: translate(0,-50%);
  top: 50%;
  position: relative;
}
@media (max-width: 756px) {
  .carousel-box-key img{  
    min-height: unset;
    object-fit: cover;
    max-width: 500px;
    width:100% !important;
    height: auto !important;
    padding: 0px;
    max-width: 500px;
  }
}

