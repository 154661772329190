.statusBar {
  width: 100%;
}
.section {
  display: flex;
  margin-left: 5px;
  margin-top: 10px;
}
.sectionMargin {
  margin-top: 10px;
}
.sectionDetail {
  display: flex;
  margin-left: 5px;
}
.pageSecurityHeadsWPaddinW{
  padding: 1px 0px 0px;
  width: 100%;
}
.sectionDetailFontStyleLeft{
  text-align: left;
  display: block;
  line-height: 15px;
}
.sectionDetailFontStyleRight{
  float: right;
  font-weight:100;
  font-size:10px;
}
.sectionDetailFontStyleSmall{
  font-family: "Poppins";
  font-weight: 100;
  font-size: 10px;
  
}
.strongStyle{
  font-weight: 700;
}
.strongStyleRight{
  font-weight: 700;
  float: right;
}
.infoTec{
  font-weight: 600;
}
.infoTecBox{
  font-weight: 100;
}
.infoTecBoxTitle{
  font-weight: 700;
  font-size: 12px;
}
.infoTecBoxText{
  font-weight: 100;
  color: rgb(109, 109, 109);
  font-size: 14px;
}
.mapContainer{
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #e8eaed;
  height: 250px;
  width: 100%;

  
}
/* border-radius: 0px 10px 10px 0px */