.formLogin {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.sharp {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}
.signInToMinimal {
  align-self: stretch;
  text-align: center;
  margin-bottom: 8px;
  font-family: "Poppins";
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  color: rgba(49, 36, 120, 1);
}
.signInToMinimalEmphasis1 {
  font-family: "Poppins";
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  color: rgba(33, 43, 54, 1);
}
.signInToMinimalEmphasis2 {
  font-family: "Poppins";
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  color: rgba(140, 170, 39, 1);
}
.enterYourDetailsB {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(91, 101, 118, 1);
  align-self: stretch;
  text-align: center;
}
.four {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.two {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.textField {
  height: 56px;
  width: 450px;
  /* &:not(:last-of-type) {
  margin-bottom: 24px;
  } */
}
.three {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
}
.forgotPassword {
  font-family: "Public Sans";
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: rgba(28, 79, 193, 1);
  text-align: right;
}
.formLogin .button {
  width: 450px;
}
.buttonTwo {
  align-self: stretch;
}