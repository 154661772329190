.cardBienvenida {
  background-color: rgba(245, 241, 235, 1);
  border-radius: 16px;
  padding: 28.5px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  display: grid; /* para centrar en Pagedasboard */
}
.textBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.tile {
  font: var(--h3);
  padding: 0;
  margin: 0;
  color: var (--secondarycolorpalette-darkblue-main);
}
.name {
  font: var(--h2);
  padding: 0;
  margin: 0;
  color: var(--tertiary-main);
  word-break: break-word;
}
.paragraph {
  font: var(--text-body1);
  color: rgba(31, 39, 57, 1);
  text-align: justify;
}
.buttonBox {
  padding: 10px 393px 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.button {
  background-color: var(--primary-main);
  color: var(--tertiary-main);
}
