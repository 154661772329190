@media (min-width: 768px) {
  .col-md-6.custom {
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }
  .col-md-4.custom {
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }
}
.row.custom {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
}
.col-custom-md-7 {
  -ms-flex: 0 0 70%;
  flex: 0 0 70%;
  max-width: 70%;
}

.col-custom-md-3 {
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  max-width: 30%;
}
.col-custom-md-6 {
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
  max-width: 60%;
}
.col-custom-md-4 {
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  max-width: 40%;
}
@media (max-width: 1300px) {
  .custom-col-1 .col-md-8 {
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }
  .custom-col-1 .col-md-4 {
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }
}
@media (max-width: 1040px) {
  .custom-col-1 .col-md-8 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 60%;
  }
  .custom-col-1 .col-md-4 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .custom-col-1 .col-md-6 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 700px) {
  .custom-col-1 .col-md-8 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .custom-col-1 .col-md-4 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .custom-col-1 .col-md-6 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.btn-group-logout-only {
  grid-template-columns: 50% 50%;
  grid-template-rows: 1fr;
}
.status-style {
  padding: 0 8px 0 8px;
  border: 1px solid transparent;
  border-radius: 8px;
  margin-left: 5px;
  display: inline-block;
  text-align: center;
}
