.page-academy .card-blog-center{
    width: 100%;
    display: flex;
}
.page-academy .card-blog-center div:first-child{
    margin: auto;
}

.textContent:hover {
    max-height: 100vh;
}



  .textContent {
    min-height: 185px;
    display: block;
    overflow: hidden;
    color:#fff;
    max-height:0;
    -webkit-transition: max-height 1.5s ease;
    -moz-transition: max-height 1.5s ease;
    transition: max-height 1.5s ease;
    padding-right: 10px;
    cursor: pointer;
  }
  .textContent .categoryTag{
    display: inline-flex;
    float: left;
  }
  .textContent .description{
    float: left;
  }