.tableViewNewInvoice {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 16px;
    padding: 17px 10px;
    box-shadow: 0 12px 24px -4px rgba(145, 158, 171, 0.12),
      0 0 2px 0 rgba(145, 158, 171, 0.2);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .card {
    align-self: stretch;
  }
  .tableViewNewInvoiceHeadTwo {
    background-color: rgba(255, 255, 255, 1);
    height: 56px;
    width: 712px;
    position: relative;
  }
  .tableViewNewInvoiceHead {
    background-color: rgba(255, 255, 255, 1);
    position: absolute;
    right: -338px;
    top: 0;
  }
  .table {
    width: calc(100% - 16px);
    border-radius: 8px;
    padding: 0 8px;
    position: relative;
  }
  .layer {
    width: 1034px;
    height: 56px;
    background-color: rgba(244, 246, 248, 1);
    border-radius: 8px;
  }
  .frame387 {
    padding: 0 29px 0 0;
    position: absolute;
    right: -2px;
    top: 7px;
  }
  .frame382 {
    padding: 10px 135px 10px 10px;
  }
  .frame383 {
    padding: 10px 120px 10px 0;
  }
  .frame384 {
    padding: 10px 42px 10px 0;
  }
  .frame495 {
    padding: 10px 32px 10px 10px;
  }
  .text {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    color: rgba(99, 115, 129, 1);
  }
  .frame496 {
    padding: 10px 21px 10px 10px;
  }
  .frame497 {
    padding: 10px 44px 10px 10px;
  }
  .frame385 {
    padding: 10px 25px 10px 10px;
  }
  .tableViewNewInvoiceRow {
    height: 66px;
    width: 1060px;
  }
  .tableTwo {
    height: 63px;
    align-self: stretch;
  }
  .card-box{
    background-color: var(--common-white);
    border-radius: 16px;
    padding: 15px;
    box-shadow: 0 12px 24px -4px rgb(145 158 171 / 12%), 0 0 2px 0 rgb(145 158 171 / 20%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }