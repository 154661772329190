.menuBlog {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.itemActive {
  background-color: rgba(210, 199, 247, 1);
  margin-bottom: 20px;
  border-radius: 16px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 176px;
}
.icon {
  margin-right: 10px;
  width: 18px;
  height: 27px;
}
.label {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  color: rgba(49, 36, 120, 1);
}
.itemMenu {
  background-color: rgba(235, 227, 215, 1);
  margin-bottom: 20px;
  border-radius: 16px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 176px;
}
.labelTwo {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  color: rgba(27, 25, 73, 1);
}
.itemMenuTwo {
  background-color: rgba(235, 227, 215, 1);
  margin-bottom: 20px;
  border-radius: 16px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 176px;
}
.itemMenuThree {
  background-color: rgba(235, 227, 215, 1);
  border-radius: 16px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;
  width: 176px;
}