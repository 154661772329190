.float-labels {
  /* display: flex;
  width: 301px; */
  display: flex;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 9px;
}
.float-labels .float-label-right {
  display: inline-block;
  position: absolute;
  right: 3px;
}
.float-labels .float-label-left {
  display: inline-block;
  position: absolute;
  left: 10px;
}
