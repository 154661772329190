.page-security {
}
.page-security-head {
  padding: 45px 20px 30px;
}
.page-security-head .page-security-subtitle {
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 700;
  line-height: 30px;
  color: rgba(28, 79, 193, 1);
  display: flex;
  padding-bottom: 10px;
}
.page-security-head .page-security-body {
  color: gray;
  font-size: 14px;
  text-align: justify;
  font-family: Poppins-ExtraLight;
}
.page-security .page-security-options {
  margin-bottom: 20px;
}
.page-security .page-security-last-update {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 11px;
  padding: 12px 18.65px 12px 18px;
  box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: #f0f0f0;
}
.page-security-l-text {
  font-size: 12px;
  color: #999898;
}
.page-security-l-body {
    font-size: 14px;
    color: #424242;
  }
