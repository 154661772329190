.chk-tc .three {
  align-items: start !important;
}
.chk-tc .chk-tc-box span.Mui-checked {
  color: var(--primary-main);
}
.chk-tc .text-field-box label {
  padding: 0 !important;
  text-align: start;
}
.foo-bar{
    font-family: Poppins;
  }