:root {

 
  --common-white: #ffffff; 
  --common-dark: #000000; 
  --primary-lighter: #dcef95; 
  --primary-light: #cbe661; 
  --primary-main: #b9de2c; 
  --primary-dark: #8caa27; 
  --primary-darker: #5f7623; 
  --secundary-lighter: #bff3ff; 
  --secundary-light: #81e7ff; 
  --secundary-main: #00cfff; 
  --secundary-dark: #019fc5; 
  --secundary-darker: #026e8c; 
  --tertiary-ligther: #9891bb; 
  --tertiary-light: #655b9a; 
  --tertiary-main: #312478; 
  --tertiary-dark: #261e60; 
  --tertiary-darker: #1b1949; 
  --text-ondark-primary: #ffffff; 
  --text-onlight-primary: #081730; 
  --text-onlight-secondary: #5b6576; 
  --background-ondark-default: #081730; 
  --background-onlight-default: #f7f7f7; 
  --secondarycolorpalette-lightpurple-lighther: #d2c7f7; 
  --secondarycolorpalette-lightpurple-light: #bbacf2; 
  --secondarycolorpalette-lightpurple-main: #a490ee; 
  --secondarycolorpalette-lightpurple-dark: #7c6fb9; 
  --secondarycolorpalette-lightpurple-darker: #554f83; 
  --secondarycolorpalette-lightblue-ligtheri: #8da7e0; 
  --secondarycolorpalette-lightblue-light: #557bd1; 
  --secondarycolorpalette-lightblue-main: #1c4fc1; 
  --secondarycolorpalette-lightblue-dark: #163e97; 
  --secondarycolorpalette-lightblue-darker: #112e6d; 
  --secondarycolorpalette-hueso-lighter: #f5f1eb; 
  --secondarycolorpalette-hueso-light: #f0eae1; 
  --secondarycolorpalette-hueso-main: #ebe3d7; 
  --secondarycolorpalette-hueso-dark: #b1ada7; 
  --secondarycolorpalette-hueso-darker: #787878; 
  --secondarycolorpalette-lightorange-lighter: #ffdca1; 
  --secondarycolorpalette-lightorange-light: #ffcb72; 
  --secondarycolorpalette-lightorange-main: #ffba43; 
  --secondarycolorpalette-lightorange-dark: #c18f38; 
  --secondarycolorpalette-lightorange-darker: #82642e; 
  --secondarycolorpalette-lightred-ligther: #fed7cf; 
  --secondarycolorpalette-lightred-light: #fec2b7; 
  --secondarycolorpalette-lightred-main: #fdae9f; 
  --secondarycolorpalette-lightred-dark: #bf867d; 
  --secondarycolorpalette-lightred-darker: #815e5c; 
  --secondarycolorpalette-darkblue-ligther: #8f939c; 
  --secondarycolorpalette-darkblue-light: #575d6b; 
  --secondarycolorpalette-darkblue-main: #1f2739; 
  --secondarycolorpalette-darkblue-dark: #182031; 
  --secondarycolorpalette-darkblue-darker: #121a29; 
  --h1: 700 2.5rem/3.125rem Poppins; 
  --h2: 700 2rem/2.625rem Poppins; 
  --h3: 700 1.5rem/2.25rem Poppins; 
  --h4: 700 1.25rem/1.875rem Poppins; 
  --h5: 700 1.125rem/1.6875rem Poppins; 
  --h6: 700 1.0625rem/1.625rem Poppins; 
  --overlineunderline: 700 0.75rem/1.125rem Futura; 
  --subtitle1: 700 1rem/1.5rem Poppins; 
  --subtitle2: 700 0.875rem/1.375rem Poppins; 
  --text-body1: 400 1rem/1.5rem Poppins; 
  --text-body1bold: 700 1rem/1.5rem Poppins; 
  --text-body2: 400 0.875rem/1.375rem Poppins; 
  --text-body2bold: 700 0.875rem/1.375rem Poppins; 
  --text-caption: 400 0.75rem/1.125rem Poppins; 
  --text-captionbold: 700 0.75rem/1.125rem Poppins; 
  --overline: 700 0.75rem/1.125rem Futura; 
  --buttons-buttonsm: 700 0.8125rem/1.375rem Poppins; 
  --buttons-buttonlg: 700 0.9375rem/1.625rem Poppins; 
  --buttons-buttonmd: 700 0.875rem/1.5rem Poppins; 
  --transparent-dark-gray: box-shadow: 0px 8px 16px 0px rgba(145, 158, 171, 0.1);


}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.button{
  cursor: pointer;
}
.btn{
  cursor: pointer;
}
.btn:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.btn.btn-white {
  background-color: #fff;
  color: #777;
}

.btn-white::after {
  background-color: #fff;
}

.btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.btn-animated {
  animation: moveInBottom 5s ease-out;
  animation-fill-mode: backwards;
}

@keyframes moveInBottom {
  0% {
      opacity: 0;
      transform: translateY(30px);
  }

  100% {
      opacity: 1;
      transform: translateY(0px);
  }
}
.foo-bar{
  font-family: Poppins;
}
.page--head .page--subtitle {
  font-family: "Poppins-Bold";
  font-size: 15px;
  font-weight: 700;
  line-height: 30px;
  color: rgba(28, 79, 193, 1);
  display: flex;
  padding-bottom: 10px;
  
}
.page-item.active .page-link
{
  z-index: 3;
  color: #fff;
  background-color: #112e6d;
  border-color: #007bff;
}
.page-link
{
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #112e6d;
  background-color: #fff;
  border: 1px solid #dee2e6;
}