.login-container {
  display: flex;
  width: 100%;
  height: 100vh;
  max-width: 1500px;
  margin: auto;
}
.column-rigth {
  width: 50%;
  height: 85% !important;
}
.column-left {
  width: 50%;
}
.login-container .container {
  width: 100%;
  text-align: center;
  margin: auto;
  display: flex;
  height: 90%;
}
.box {
  /* display: inline-block;
  width: 50%;
  height: 100%; */
  display: grid;
  width: 50%;
  height: 100%;
  margin: auto;
}
.login-img {
  margin: auto;
  margin: auto;
  display: inherit;
  width: 90%;
  min-height: calc(100%);
  /* height: 100% !important; */
}
.img-container {
  height: -webkit-fill-available;
  border-radius: 10px;
  overflow: hidden;
}
.img-container img {
  object-fit: cover;
  width: 100%;
  height: 100% !important;
}
@media screen and (max-width: 960px) {
  .box {
    display: inline;
    width: 100%;
    height: 100%;
  }
  .column-left {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .login-img {
    margin: auto;
    width: 340px;
  }
}
.copy-rigth {
  margin-top: 20px;
}
.copy-rigth .float-content {
  font-size: 12px;
  color: var(--text-onlight-secondary);
  /*  transform: translate(0px, 40px);
    height: 40px; */
}
/* rewrite bootstrap*/
@media (min-width: 1200px) {
  .login-container .container {
    max-width: 1500px;
  }
}

@media (min-width: 992px) {
  .container {
    /* max-width: 960px; */
  }
}

@media (min-width: 768px) {
  .container{
    /* max-width: 720px; */
  }
}

@media (min-width: 576px) {
  .container {
    /* max-width: 540px; */
  }
}
/* rewrite bootstrap*/
