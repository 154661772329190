.segments {
    display: flex;
}
.sidebar {
    
    box-shadow: 3px 0px 15px 0px rgb(0 0 0 / 7%);
    z-index: 1000000000;
    position: relative;
    background: white;
}
.segment-container {
    width: 100%;
    transition: width .5s;
}
.segment-container .container-outlet{
    width: 100%;
}
.padding-box{
    padding: 30px 15px 20px 15px;
}
.menu-container{
    width: 280px;
    transition: width .3s;
    overflow: hidden;
}
.menu-container.show{
    width: 280px;
    transition: width .3s;
    overflow-y: auto;
    min-height: 100vh;
}
.menu-container.hide{
    width: 0px;
    overflow: hidden;
}
.logout{
    height: 40px;
}
.logout .logout-btn{
   
   bottom: 10px;
   right: 0px;
}
.logout-btn-box {
    width: 280px;
    cursor: pointer;
    color: var(--tertiary-main);
}


@media screen and (max-width: 968px) {
   
    .menu-container{
        width: 0px;
        overflow: hidden;
        height: 100vh;
    }
    .sidebar{
        position: fixed;
        height: 100vh;
        overflow: auto;
    }
}

