.marker-container {
  width: max-content;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 99999999;
}
.textStyle {
  -webkit-text-stroke-color: #dedede; /* Your desired color */
  text-shadow: 1px 1px 1px #dedede, -0px 0px 0 #dedede, -0px -0px 0 #dedede,
    0px -0px 0 #fff;
  position: absolute;
  bottom: calc(100% + 0px);
  text-align: center;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 0px;
  width: max-content;
  font-weight: 700;
  color: var(--secondarycolorpalette-lightblue-darker);
  font-size: 15px;
}
/* .marker-box{

} */
