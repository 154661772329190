.item-box{
    div.showing{
        background: #1c4fc1;
        p.p-title{
            /* color:#1c4fc1; */
        }
        .filter-green{
            filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
        }
    }
    
    
}
.item-childs.none{
   /*  display: none; */
}
.active-sub-menu{
    position: absolute;
    left: -25px;
    text-align: right;
    top: -12px;
    background: transparent !important;
}
.active-sub-menu::after{
    content: '\2022';
    color: #1c4fc1;
    font-size: 35px;
}